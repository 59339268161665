import { Address } from '../../types/Address.types';
import { My } from '../../types/My.types';
import FirebaseSource from '../source/FirebaseSource';

class UserRepo {
    private source: FirebaseSource;

    constructor(firebaseSource: FirebaseSource) {
        this.source = firebaseSource;
    }

    getUser = async () => {
        return await this.source.getUser();
    };

    getUserId = async () => (await this.getUser())?.id;

    getIsGuest = async () => {
        const user = await this.getUser();
        if (!user) return true;
        return user.isAnonymous;
    };

    getAnonId = async () => {
        const userId = await this.getUserId();
        if (userId) return userId;
        await this.source.signInAnonymously();
        return await this.getUserId();
    };

    get userIdFlow() {
        return this.source.userIdFlow;
    }

    submitPhone = async (number: string) => {
        await this.source.submitPhone(number);
    };

    submitCode = async (number: string) => {
        await this.source.submitCode(number);
    };

    signOut = async () => {
        await this.source.signOut();
    };

    getMy = async () => {
        const user = await this.source.getCurrentUser();
        if (!user) return null;
        return await this.source.getDocument<My & { id: string }>('/my', user.id);
    };

    updateAddress = async (name: string, address: Address) => {
        const user = await this.source.getUser();
        await this.source.updateName(name);
        await this.source.updateDocument('my', user.id, { address });
    };

    updatePhotoUrl = async (url: string | null) => {
        const user = await this.source.getCurrentUser();
        if (!user) throw new Error('Must sign in to upload photos');
        await this.source.updatePhotoUrl(url);
    };
}

export default UserRepo;
