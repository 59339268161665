export enum Nav {
    ROOT = '/',
    MANAGER_CREATE_STORY = '/manager/create',
    NOT_FOUND = '*',
    SIGNIN = '/login',
    PROMOTIONS = '/promotions',
    PROMOTION_EDIT = '/promotions/edit/*',
    PROMOTION_ITEM = '/promotions/item/*',
    PROMOTION_ITEM_PRIZE = '/promotions/prize/*',
    PROMOTION_CREATE = '/promotions/create',
    REPORTS = '/stories/reports',
    WORLD = '/stories/world/*',
    CREDITS = '/credits',
    HOME = '/home',
    STORIES = '/stories',
    STORY = '/stories/story/*',
    SETTINGS = '/settings',
    EMAIL = '/settings/email',
    CODE = '/settings/ecode',
    PHONE = '/settings/phone',
    ADDRESS = '/settings/address',
    AVATAR = '/settings/avatar',
    NAME = '/settings/name',
    SIGNOUT = '/settings/signout',
    STORY_EDIT = '/stories/edit/*',
    QRCODE = '/stories/qrcode/*',
    CHATS = '/chats',
    CHAT = '/chats/chat/*',
    LENSWIZ = '/stories/lens',
    LENSWIZ_START = '/stories/lens/start/*',
    LENSWIZ_STORY = '/stories/lens/story/*',
    LENSWIZ_CONTENT = '/stories/lens/content/*',
    LENSWIZ_TP = '/stories/lens/touchpoint/*',
    LENSWIZ_READY = '/stories/lens/ready/*',
    CLOSE = '/close',
    MORE = '/more',
    OTP_CODE = '/otp',
    PRIVACY = '/privacy',
    TOS = '/tos',
    SCENES = '/scenes/*',
}

export interface NavItem {
    path: Nav;
    title: string;
    short?: string;
    auth?: number;
    owner?: boolean;
    isFullScreen?: boolean;
    showAppBar?: boolean;
    badge?: string | number;
}

enum Auth {
    GUEST = 1,
    ANON = 2,
    AUTH = 4,
}

export const authAll = Auth.ANON | Auth.GUEST | Auth.AUTH;

export const wildCardNav = (path: Nav, key: string) => {
    const pathAsString = path as string;
    if (!pathAsString.endsWith('/*')) return pathAsString;
    const paths = pathAsString.split('/');
    paths.pop();
    paths.push(key);
    return paths.join('/');
};

export const wildCardNavs = (path: Nav, keys: string[]) => {
    return wildCardNav(path, keys.join('/'));
};
