export const ROOT = 'https://clyps.app';

export const isValidHttpsUrl = (s?: string | null) => {
    if (!s) return false;

    let url;

    try {
        url = new URL(s);
    } catch (_) {
        return false;
    }

    return url.protocol === 'https:' || isBlob(s);
};

/**
 * @deprecated use isValidHttpsUrl
 * @param s
 * @returns
 */
export const isValidHttpUrl = (s?: string | null) => {
    if (!s) return false;

    let url;

    try {
        url = new URL(s);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:' || url.toString().startsWith('blob:');
};

export const isValidUrl = (s?: string | null) => {
    try {
        return !!new URL(s ?? '');
    } catch (_) {
        return false;
    }
};

export const generateNameFromFile = (name: string, forceExt?: string) => {
    const parts = name?.split('.') ?? [];
    const ext = parts.findLast((_, idx) => idx > 0) ?? 'jpg';
    if (parts.length > 1) parts.pop();
    parts.push(new Date().getTime().toString());
    return `${parts.map((it) => it.replace(/[^0-9a-z]/gi, '')).join('_')}.${forceExt ?? ext}`;
};

export const rootPath = () => {
    const a = window.location.href.split('/');
    return `${a[0]}//${a[2]}`;
};

export const isBlob = (url: string) => url.startsWith('blob:');

export const toFullUrl = (path?: string | null) => {
    if (path?.startsWith('/')) path = path.substring(1);
    if (!path?.trim()) return ROOT;
    return `${ROOT}/${path.trim()}`;
};

export const toAppPath = (path?: string | null) => {
    if (path?.startsWith('/')) return path;
    if (!path) return '/';
    if (path.toLowerCase().startsWith(ROOT)) path = path.substring(ROOT.length);
    if (isValidHttpsUrl(path)) return path;
    if (!path.startsWith('/')) path = '/' + path;
    return path;
};
