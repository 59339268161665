import UserRepo from '../../data/auth/UserRepo';
import { FirebaseSourceProps } from './lensinjector';

export const userProviders = {
    getUserId: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.getUserId,
        single: false,
    },
    getIsGuest: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.getIsGuest,
        single: false,
    },
    getUser: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.getUser,
        single: false,
    },
    userRepo: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => new UserRepo(firebaseSource),
        single: true,
    },
    getAnonId: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.getAnonId,
        single: false,
    },
    userIdFlow: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.userIdFlow,
        single: true,
    },
    submitPhone: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.submitPhone,
        single: false,
    },
    submitCode: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.submitCode,
        single: false,
    },
    signOut: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.signOut,
        single: false,
    },
    getCurrentUser: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.getCurrentUser,
        single: false,
    },
    getMy: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.getMy,
        single: false,
    },
    updateAddress: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.updateAddress,
        single: false,
    },
    updateName: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.updateName,
        single: false,
    },
    signInWithWebOtp: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.signInWithWebOtp,
        single: false,
    },
    submitEmail: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.submitEmail,
        single: false,
    },
    submitEmailCode: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.submitEmailCode,
        single: false,
    },
    updatePhotoUrl: {
        provider: ({ userRepo }: UserRepoProps) => userRepo.updatePhotoUrl,
        single: false,
    },
};

type UserRepoProps = { userRepo: UserRepo };
