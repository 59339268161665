import { isValidUrl, toAppPath } from './utils/url';

export interface IRouter {
    navigate(appPath: string | number, options?: IRouterOptions): unknown;
}

export type IRouterOptions = {
    replace?: boolean;
};

export const createRouterProvider = (router: IRouter) => ({
    navigate: (path: string) => {
        if (isValidUrl(path)) {
            const appPath = toAppPath(path);
            if (appPath.startsWith('/')) {
                router.navigate(appPath);
            } else {
                window.open(path);
            }
        } else {
            router.navigate(path);
        }
    },
    navigateReplace: (nav: string) => router.navigate(nav, { replace: true }),
    navigateUp: () => router.navigate(-1),
});
