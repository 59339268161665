import { Outlet, RouteObject } from 'react-router-dom';
import { Nav } from './ui/common/Nav.type';
import React from 'react';
import AppTheme from './ui/theme/AppTheme';
import { env } from './ui/di/lensinjector';
import QueryProvider from './ui/common/QueryProvider';

const Promotions = React.lazy(() => import('./ui/promotions/Promotions'));
const PromotionsList = React.lazy(() => import('./ui/promotions/list/PromotionsList'));
const PromoEditor = React.lazy(() => import('./ui/promotions/editor/PromoEditor'));
const PrizeItem = React.lazy(() => import('./ui/promotions/item/prizes/item/PrizeItem'));
const HomeWrapper = React.lazy(() => import('./ui/wrapper/HomeWrapper'));
const StoryPage = React.lazy(() => import('./ui/stories/story/StoryPage'));
const Email = React.lazy(() => import('./ui/settings/email/Email'));
const SettingsContainer = React.lazy(() => import('./ui/settings/SettingsContainer'));
const Phone = React.lazy(() => import('./ui/settings/phone/Phone'));
const Stories = React.lazy(() => import('./ui/stories/all/Stories'));
const Settings = React.lazy(() => import('./ui/settings/Settings'));
const Reports = React.lazy(() => import('./ui/reports/Reports'));
const Name = React.lazy(() => import('./ui/settings/name/Name'));
const Addr = React.lazy(() => import('./ui/settings/address/Addr'));
const EditStoryPage = React.lazy(() => import('./ui/stories/story/EditStoryPage'));
const World = React.lazy(() => import('./ui/stories/world/World'));
const PromotionItem = React.lazy(() => import('./ui/promotions/item/PromotionItem'));
const LensWiz = React.lazy(() => import('./ui/stories/lenswiz/LensWiz'));
const LensWizStart = React.lazy(() => import('./ui/stories/lenswiz/start/LensWizStart'));
const LensWizStory = React.lazy(() => import('./ui/stories/lenswiz/poster/LensWizPoster'));
const LensWizContent = React.lazy(() => import('./ui/stories/lenswiz/content/LensWizContent'));
const LensWizTp = React.lazy(() => import('./ui/stories/lenswiz/tp/LensWizTp'));
const SingleChat = React.lazy(() => import('./ui/chats/chat/SingleChat'));
const Chats = React.lazy(() => import('./ui/chats/Chats'));
const Credits = React.lazy(() => import('./ui/credits/Credits'));
const LensWizReady = React.lazy(() => import('./ui/stories/lenswiz/finish/LensWizFinish'));
const SignOut = React.lazy(() => import('./ui/settings/signout/SignOut'));
const OtpCode = React.lazy(() => import('./ui/otp/OtpCode'));
const TOS = React.lazy(() => import('./ui/legal/tos'));
const Privacy = React.lazy(() => import('./ui/legal/Privacy'));
const ImageFeatures = React.lazy(() => import('./ui/lab/ImageFeatures'));
const Scenes = React.lazy(() => import('./ui/scenes/Scenes'));
const Avatar = React.lazy(() => import('./ui/settings/avatar/Avatar'));

const routes: Array<RouteObject> = [
    {
        path: Nav.ROOT,
        element: (
            <React.Suspense>
                <AppTheme>
                    <QueryProvider>
                        <HomeWrapper env={env} />
                    </QueryProvider>
                </AppTheme>
            </React.Suspense>
        ),
        children: [
            {
                index: true,
                element: <></>,
            },
            {
                path: Nav.SCENES,
                element: <Scenes env={env} />,
            },
            {
                path: Nav.STORIES,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <Stories env={env} />,
                    },
                    {
                        path: Nav.STORY,
                        element: <StoryPage env={env} />,
                    },
                    {
                        path: Nav.STORY_EDIT,
                        element: <EditStoryPage env={env} />,
                    },
                    {
                        path: Nav.WORLD,
                        element: <World env={env} />,
                    },
                    {
                        path: Nav.LENSWIZ,
                        element: <LensWiz />,
                        children: [
                            {
                                index: true,
                                element: <LensWizStart env={env} />,
                            },
                            {
                                element: <LensWizStart env={env} />,
                                path: Nav.LENSWIZ_START,
                            },
                            {
                                element: <LensWizStory env={env} />,
                                path: Nav.LENSWIZ_STORY,
                            },
                            {
                                element: <LensWizContent env={env} />,
                                path: Nav.LENSWIZ_CONTENT,
                            },
                            {
                                element: <LensWizTp env={env} />,
                                path: Nav.LENSWIZ_TP,
                            },
                            {
                                element: <LensWizReady env={env} />,
                                path: Nav.LENSWIZ_READY,
                            },
                        ],
                    },
                ],
            },
            {
                path: Nav.CREDITS,
                element: <Credits env={env} />,
            },
            {
                path: Nav.CHATS,
                element: <Outlet />,
                children: [
                    {
                        element: <Chats env={env} />,
                        index: true,
                    },
                    {
                        element: <SingleChat env={env} />,
                        path: Nav.CHAT,
                    },
                ],
            },
            {
                path: Nav.SETTINGS,
                element: <SettingsContainer />,
                children: [
                    {
                        index: true,
                        element: <Settings env={env} />,
                    },
                    {
                        path: Nav.EMAIL,
                        element: <Email env={env} />,
                    },
                    {
                        path: Nav.PHONE,
                        element: <Phone env={env} />,
                    },
                    {
                        path: Nav.ADDRESS,
                        element: <Addr env={env} />,
                    },
                    {
                        path: Nav.NAME,
                        element: <Name env={env} />,
                    },
                    {
                        path: Nav.AVATAR,
                        element: <Avatar env={env} />,
                    },
                    {
                        path: Nav.SIGNOUT,
                        element: <SignOut env={env} />,
                    },
                ],
            },
            {
                path: Nav.PROMOTIONS,
                element: <Promotions />,
                children: [
                    {
                        index: true,
                        element: <PromotionsList env={env} />,
                    },
                    {
                        path: Nav.PROMOTION_CREATE,
                        element: <PromoEditor env={env} />,
                    },
                    {
                        path: Nav.PROMOTION_EDIT,
                        element: <PromoEditor env={env} />,
                    },
                    {
                        path: Nav.PROMOTION_ITEM_PRIZE,
                        element: <PrizeItem env={env} />,
                    },
                    {
                        path: Nav.PROMOTION_ITEM,
                        element: <PromotionItem env={env} />,
                    },
                ],
            },
            {
                path: Nav.REPORTS,
                element: <Reports env={env} />,
            },
            {
                path: Nav.PRIVACY,
                element: <Privacy />,
            },
            {
                path: Nav.TOS,
                element: <TOS />,
            },
        ],
    },
    {
        path: Nav.OTP_CODE,
        element: (
            <React.Suspense>
                <OtpCode env={env} />
            </React.Suspense>
        ),
    },
    {
        path: 'lab/features',
        element: <ImageFeatures />,
    },
    {
        path: '*',
        element: <>NOT FOUND</>,
    },
];

export { routes };
